 * {
   scroll-behavior: smooth;
   /* overflow-x: hidden; */
 }

 .navbar {
   /* background: rgb(222, 38, 26); */
   /* background: linear-gradient(90deg, rgba(222, 38, 26, 1) 0%, rgba(42, 21, 114, 1) 100%); */
   background-color: white;
 }

 .navlink {
   color: white !important;
   font-weight: 500;
 }

 .head_clr {
   color: #2a1672;
 }

 .cardH {
   background-color: whitesmoke;
   color: #000000;
 }

 .cardH:hover {
   /* zoom: 101%; */
   transition: 0.4s;
   -webkit-box-shadow: -2px 3px 16px -7px rgba(0, 0, 0, 0.75);
   -moz-box-shadow: -2px 3px 16px -7px rgba(0, 0, 0, 0.75);
   box-shadow: -2px 3px 16px -7px rgba(0, 0, 0, 0.75);
   background-color: white;
 }

 .kno_mre_btn {
   background-color: #2A1672;
   border: none;
 }

 .cardH:hover .kno_mre_btn {
   background-color: #B12230;
   transition: 0.3s;

 }


 .imghov:hover {
   -webkit-transform: scale(5.1);
   transform: scale(1.1);
   transition: 0.3s;
 }

 a {
   text-decoration: none;
   color: #000000;
   font-weight: 500;
 }

 .cho_bg {
   background-color: #ffc107;
 }

 .foter_cont {
   background-color: #101010;
 }



 .single-car-bg.bgclr-1 {
   background-image: orange;
   background-repeat: no-repeat;
   background-size: cover;
 }

 .single-car-bg {
   overflow: hidden;
 }

 .single-car-bg {
   min-height: 320px;
   padding: 40px;
   height: 100%;
 }

 .car-btm-img {
   transform: translateX(40%);
   transition: all 0.5s ease-in-out;
   padding-top: 20px;
 }

 .single-car-bg:hover .car-btm-img {
   transform: translateX(0);
 }

 .numbers {
   display: flex;
   justify-content: space-around;
   align-items: center;
   margin-top: 50px;
 }

 .numbers .counter {
   color: #291671 !important;
 }

 .numbers span {
   color: #5b1959 !important;
   font-weight: 600;
   margin-top: 20px;
 }

 .numbers img {
   width: 12%;
   margin-right: 15px;
 }

 .numbers .count_cont {
   display: flex;
   justify-content: center;
   align-items: center;

 }

 .list li {
  list-style: none; /* Remove default bullet points */
  padding-left: 30px; /* Adjust space for the image */
  background: url("../src/Asserts/liicon.png") no-repeat left center;
  background-size: 20px 20px; /* Adjust the size of the image */
  margin-top: 10px;
}



.serli li {
  list-style: none; /* Remove default bullet points */
  padding-left: 50px;
  background: url("../src/Asserts/serarow.png") no-repeat left center;
  background-size: 20px 20px; /* Adjust the size of the image */
  margin-top: 10px;
  /* padding-right: 20px; */
  font-weight: 500;
}

.navlink {
  display: inline-block;
  position: relative;
  color: #2a1571 !important;
  text-decoration: none;
  min-width: 100px!important;
}
.navlink::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 5px;
  height: 0.05em;
  bottom: 0;
  left: 0;
  background: #2a1571;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.navlink:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

 
.image-gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery-image {
  width: 350px; /* Adjust as needed */
  height: 250px; /* Adjust as needed */
  margin: 10px;
  object-fit: cover;
}

.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.top_bar p{
  cursor: pointer;
}
 


.top_bar a{
color: white;
}


.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  max-width: 262px;
  background-color: #ffc107;
  border-radius: 4px;
  padding: 10px 12px 0px 15px;
  /* margin: 12px; */
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}
.card1:hover:before {
  transform: scale(21);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}